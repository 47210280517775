import React, { Component } from 'react'

import "./Inventory.css";

import * as API from "../../helpers/API";
import { Link } from 'react-router-dom';

import AuxLayout from "../../helpers/AuxLayout";
import ActionCard from '../ActionCard';

import ShoppingCartIcon from '../../assets/icons/cart.svg';
import TradeIcon from '../../assets/icons/trade.svg';
import FilterBar from '../FilterBar';
import CardView from '../CardView';

import Inspect from '../Inspect/Inspect';
import Loader from '../Loader';
import Pagination from '../Pagination';
import Swal from 'sweetalert2';
import CatalogHelper from '../../helpers/CatalogHelper';
import replaceIpfsHash from "../../helpers/ipfsDebugger";
import AtomicAssetsAPI from "../../helpers/AtomicAssetsAPI";
import config from '../../config/config';
import Catalog from '../../container/Catalog/Catalog';

export default class InventoryView extends Component {

	_mounted = false;

	state = {
		loaded: false,
		items: [],

		// Filter
		rarity: "",
		search: "",
		sort: "",
		special_filter: "",
		//
		inspect: null,
		highlighted: []
	};

	componentDidMount() {
		this._mounted = true
		this.loadInventories(this.props.accountName);
		let highlighted = localStorage.getItem("inv_highlighted");
		if (highlighted) {
			this.setState({ highlighted: highlighted.split(",") })
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	_loadInventories = async (accountName, page = 1, limit = 1000) => {
		let options = {
			owner: accountName,
			collection_name: this.props.collection,
			limit,
			page
		};

		if (this.props.schemaWhitelist && this.props.schemaWhitelist.includes('packs')) {
			// If packs, restrict to the two series 1 packs
			options.template_id = Object.entries(config.templates).map(item => {
				return item[1];
			});
		}

		let items = await AtomicAssetsAPI.getAssets(options);

		const hasMore = (items.length === limit);

		items = items.filter(item => {
			if (this.props.schemaWhitelist) {
				return this.props.schemaWhitelist.includes(item.schema.schema_name);
			} else {
				return true;
			}
		})

		if (hasMore)
			return [
				...items,
				...(await this._loadInventories(accountName, page + 1))
			]
		else
			return items
	}

	loadInventories = async (accountName) => {
		const items = await this._loadInventories(accountName);

		this.setState({ loaded: true, items })
	}

	filterChange = change => {
		this.setState(change);
	};

	onCardClick = (item) => {
		if (this.props.onClick)
			this.props.onClick(item);
		else
			this.setState({ inspect: item })
	}

	render() {
		let items = this.state.items.map(item => {
			let name = item.name, img = item.data.img;
			if (img && !img.includes("http")) img = "https://ipfs.hivebp.io/ipfs/" + img;
			item.data.img = img;
			return item;
		}).sort((item1, item2) => {
			const a = parseInt(item1.updated_at_time);
			const b = parseInt(item2.updated_at_time);
			return a - b;
		})
		// if (this.state.loaded && items.length === 0 && global.wax && this.props.accountName === global.wax.userAccount) return (
		// 	<AuxLayout>
		// 		<Link to={"/shop"}><ActionCard className={"half small"} img={ShoppingCartIcon}
		// 									   text={"Buy Packs"} /></Link>
		// 		<Link to={"/trade"}><ActionCard className={"half small"} img={TradeIcon} text={"Trade"} /></Link>
		// 	</AuxLayout>
		// );
		if (this.state.loaded && items.length === 0) return <center><small><b>{this.props.accountName}</b> doesn't have
			anything in their inventory
			yet.</small></center>;
		let { rarity, search, special_filter } = this.state;
		if (special_filter !== "") {
			// Todo
			// if (special_filter === "missing") {
			//     let tempSelectors = items.map(x => {
			//         if (x.mdata.shardid != null) return (x.mdata.cardid + "shard").toLowerCase();
			//         return (x.mdata.cardid + x.mdata.rarity).toLowerCase();
			//     });
			//     items = (CatalogHelper.getSeries("shatner").filter(card => {
			//         console.log(card)
			//         if (tempSelectors.includes((card.cardid + card.rarity).toLowerCase())) return false;
			//         return true;
			//     }).map(card => {
			//         let nftMdata = {
			//             backimg: card.images.back,
			//             cardid: card.cardid,
			//             img: card.images.front,
			//             name: card.name,
			//             rarity: card.rarity.toLowerCase()
			//         };
			//         let nft = {
			//             author: "shatner",
			//             category: "shatner",

			//             // shortcut nft data
			//             img: card.images.front,
			//             name: card.name,

			//             owner: null,
			//             idata: {},
			//             mdata: nftMdata,
			//             assetid: null,
			//             requireclaim: 0,
			//         }
			//         return nft;
			//     }));
			// }
			if (special_filter === "duplicates") {
				let checked = [];
				items = items.filter(x => {
					let q = x.template ? x.template.template_id : JSON.stringify(x.data);
					if (checked.includes(q)) {
						return true;
					}
					checked.push(q);
					return false;
				})
			}
			if (special_filter === "missing") {
				items = CatalogHelper.getSeries().filter(catalogItem => {
					let cardsFound = items.filter(ownedItem => {
						return catalogItem.name == ownedItem.name && catalogItem.rarity == ownedItem.data.rarity
					})
					if (cardsFound.length == 0) return true;
					return false;
				}).map(catalogItem => {
					return {
						assetid: -1,
						collection: {
							collection_name: config.collection_name
						},
						data: {
							img: catalogItem.images.front,
							backimg: catalogItem.images.back,
							rarity: catalogItem.rarity,
							name: catalogItem.name,
						},
						name: catalogItem.name,
						owner: null,
						schema: {schema_name: "series1"}
					}
				});
			}
		}

		if (rarity && rarity.length > 0) items = items.filter(x => x.data.rarity === rarity);

		if (search) items = items.filter(x => x.name.toLowerCase().includes(search.toLowerCase()));

		let newItems = [];
		while (items.length) {
			const item = items.pop();
			newItems.push({
				...item,
				sortAssetId: parseInt(item.asset_id),
				cardid: item.data.cardid
			});
		}

		return (
			<AuxLayout>
				<FilterBar onChange={this.filterChange} showSort={true} wetSettings={true} />
				<Pagination wrapper={items => (
					<div id="invview" className={"card-wrapper"}>
						{!this.state.loaded ? <Loader /> : null}
						{items}
						{
							this.state.loaded && items.length === 0 ?
								<center><small>No cards found.</small></center> : null
						}
						<Inspect
							id={this.state.inspect ? this.state.inspect.id : 0}
							item={this.state.inspect}
							specialFilter={special_filter}
							withdraw={this.withdraw}
							type={"card"}
							active={this.state.inspect}
							close={(reload) => {
								if (reload) {
									this.setState({ inspect: null, items: [] });
									this.loadInventories(this.props.accountName);
								} else {
									this.setState({ inspect: null });
								}
							}}
							{...this.state.inspect}
						/>
					</div>
				)} items={newItems.map((item, i) => <CardView key={item.id}
															  item={item}
															  owned={this.props.accountName === global.wax.userAccount}
															  onClick={() => {
																  this.onCardClick(item)
															  }} />)} />
			</AuxLayout>
		)
	}
}
