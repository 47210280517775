import React, { Component } from 'react';

import "./Catalog.css";

import * as API from "../../helpers/API";
import FilterBar from '../../components/FilterBar';

import Card from '../../components/Card/Card';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import config from "../../config/config";

class Catalog extends Component {
	_mounted = false;
	state = {
		loaded: false,
		catalog: [],

		series: "",
		rarity: "",

		search: "",
		only_wet: false,

		mobile: window.innerWidth < 400,
		cardHeight: 350,
		cardWidth: 250
	};

	componentDidMount() {
		this._mounted = true;
		this.loadCatalog(this.state.series);
		this.resizeListener = window.addEventListener("resize", e => {

			if (this._mounted && this.state.mobile && window.innerWidth < 400) {
				if (this._mounted) {
					this.setState({ mobile: window.innerWidth < 400 });
				}
			}
		})
	}

	checkCardResize = () => {

	}

	componentWillUnmount() {
		this._mounted = false;
		if (this.resizeListener) window.removeEventListener("resize", this.resizeListener)
	}

	loadCatalog = async () => {
		API.getCatalog(this.state.series).then(cards => {
			this.setState({ loaded: true, catalog: cards });
		}).catch(console.error)
	}

	filterChange = change => {
		this.setState(change);
	}

	render() {
		if (!this.state.loaded) return (<div className="container" id="catalog"><Loader /></div>)

		let { rarity, search } = this.state;

		let cards = this.state.catalog;
		if (rarity && rarity.length > 0) cards = cards.filter(x => x.rarity === rarity);
		if (search) cards = cards.filter(x => x.name.toLowerCase().includes(search.toLowerCase()));
		let { cardHeight, cardWidth } = this.state;
		if (this.state.mobile) {
			cardHeight = cardHeight / cardWidth * 150;
			cardWidth = 150;
		}
		cards = cards.map((card, i) => {
			let { front, back } = card.images;
			if (!front.includes("http")) front = "https://ipfs.hivebp.io/ipfs/" + front;
			if (!back.includes("http")) back = "https://ipfs.hivebp.io/ipfs/" + back;
			return (
				<div key={`${card.name}${card.rarity}`} style={{ width: cardHeight + "px", height: cardHeight + "px", marginBottom: "25px" }}>
					<div className={"card-wrapper"}
						 style={{ width: (config.isPortrait(card.cardid) ? cardHeight : cardWidth) + "px", height: cardHeight + "px" }}>
						<Card src={front} back={back} height={cardHeight} cardid={card.cardid} width={cardWidth} />
					</div>
				</div>
			);
		});
		return (
			<div className="container" id="catalog">
				<div>
					<h1>Search through all Blockchain Heroes NFTs on WAX</h1>
					<FilterBar noSpecial={true} onChange={this.filterChange} defaultState={
						{
							rarity: "",
							search: "",
						}
					} />
				</div>
				{cards.length > 0 ? <Pagination wrapper={items => <div className="card-list">{items}</div>} items={cards} /> : null}
				{cards.length === 0 ? <center>No cards found</center> : null}
			</div>
		);
	}
}

export default Catalog;
