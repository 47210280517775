import React, { Component } from 'react'

import Loader from '../Loader';

import { Redirect, Link } from 'react-router-dom';

import "./PurchaseForm.css"
import AuxLayout from '../../helpers/AuxLayout';

import {
	ElementsConsumer,
	Elements
} from "@stripe/react-stripe-js";
import StripeHandler from '../../helpers/StripeHandler';
import PaymentAPI from '../../helpers/lib/PaymentAPI';
import Swal from 'sweetalert2';
import MoonPayIntegration from './MoonPayIntegration';
import { buyPacks } from '../../helpers/API';
import config from '../../config/config'

class CheckoutForm extends Component {
	_mounted = false;
	_fetchingMoonPay = false;

	constructor(props) {
		super(props);
		this.state = {
			"loading": false,
			buyLoading: false,
			"balanceLoaded": false,
			"step": 0,
			"balance": 0,

			"account_name": props.userAccount,
			"quantity": props.product && props.product.minPurchase ? props.product.minPurchase : 1,
			"token": props.product.token,
			"buywax": {
				"enabled": false,
				"amount": 0
			},
			timeRemaining: 999999
		}
	}

	componentDidMount() {
		this._mounted = true;
		this.loadBalance();
		this.updateTime();
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	updateTime() {
		if (!this._mounted)
			return;

		const newTimeRemaining = parseInt(config.unlockTime - new Date().getTime())
		this.setState({
			timeRemaining: (newTimeRemaining)
		})
		setTimeout(() => {
			this.updateTime();
		}, 1000);
	}

	login = async () => {
		await Swal.showValidationMessage("Test")
		let userAccount = await global.wax.login();
		if (userAccount) {
			this.props.onAuthUpdate(userAccount);
		}
		global.wax.whitelistedContracts = global.whitelistedContracts;
	}

	handleQuantity = e => {
		if (e) e.preventDefault();
		let updated = parseInt(e.target.value);
		if (updated.toString() === "NaN") this.setState({quantity: e.target.value});
		if (updated >= this.props.product.minPurchase && updated <= this.props.product.maxPurchase) this.setState({ quantity: updated });
	}

	addQuantity = (am, e) => {
		if (e) e.preventDefault();
		let updated = parseInt(this.state.quantity) + am;
		if (updated >= this.props.product.minPurchase && updated <= this.props.product.maxPurchase) this.setState({ quantity: updated });
	}

	calculateTotal = () => {
		let total = this.state.quantity * this.props.product.price
		return total;
	}

	fixSupplyCount = supply => {
		return supply;
		let res = supply;
		if (supply > 10) res = "10+";
		if (supply > 50) res = "50+";
		if (supply > 250) res = "250+";
		if (supply > 500) res = "500+";
		if (supply > 1000) res = (Math.floor(supply / 100) / 10).toFixed(1) + "k";
		return res;
	}

	checkout = async e => {
		e.preventDefault()
		if (this.state.buyLoading)
			return;

		this.setState({ buyLoading: true })
		let product = this.props.product;

		if (!this.props.userAccount) return this.login();
		if (!this._mounted) return;
		let res;
		let pack = this.props.product;
		buyPacks(this.state.quantity, pack.templateId, `${parseFloat(parseFloat(pack.price) * this.state.quantity).toFixed(8)} WAX`).then(async () => {
			await Swal.fire({
				title: 'Purchased Packs',
				icon: 'success',
				confirmButtonText: 'Ok'
			});
			this.setState({ redirect: "/inventory", buyLoading: false })
		}).catch(async error => {
			await Swal.fire({
				title: 'Failed to purchase packs',
				text: error.message.replace("assertion failure with message: ", ""),
				icon: 'error',
				confirmButtonText: 'Ok'
			});
			this.setState({ buyLoading: false })
			// this.setState({ error: "An error occured. " + error })
		})
	}

	loadBalance = () => {
		if (!this._mounted) return;
		fetch("https://api.waxnet.io/v1/chain/get_currency_balance", {
			"body": JSON.stringify({ "code": "eosio.token", "account": global.wax.userAccount, "symbol": "WAX" }),
			"method": "POST"
		}).then(response => response.json())
			.then(res => {
				if (res && res.length > 0) {
					let [amount, quantity] = res[0].split(" ");
					amount = parseFloat(amount);
					if (!this._mounted) return;
					this.setState({ balance: amount, balanceLoaded: true })
					setTimeout(() => {
						this.loadBalance();
					}, 1500)
				}
			});
	}

	buyWAX = async (amountWAX, e) => {
		e.preventDefault();
		if (this._fetchingMoonPay) return;
		this._fetchingMoonPay = true;
		// Fetch moonpay price
		let waxPriceUSD = await fetch(`https://api.moonpay.io/v3/currencies/waxp/price?apiKey=${process.env.REACT_APP_MOONPAY_PUBLIC_KEY}`)
			.then(res => res.json())
			.then(res => res.USD);
		let amount = amountWAX * waxPriceUSD;
		console.log(amountWAX, waxPriceUSD, amount);
		this.setState({
			buywax: {
				enabled: true,
				amount
			}
		}, () => {
			this._fetchingMoonPay = false;
		})
	}

	fixTimeNumber = number => {
		if (number < 10)
			return '0' + number;
		return number;
	}

	secondsToString = (seconds) => {
		let numyears = Math.floor(seconds / 31536000);
		let numdays = Math.floor((seconds % 31536000) / 86400);
		let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
		let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
		let numseconds = ((((seconds % 31536000) % 86400) % 3600) % 60).toFixed();
		return this.fixTimeNumber(numhours) + ":" + this.fixTimeNumber(numminutes) + ":" + this.fixTimeNumber(numseconds);
	}


	render() {
		const { stripe } = this.props;
		if (this.state.redirect) return <Redirect to={this.state.redirect} />
		if (this.state.loading) return <Loader />
		return (
			<form autoComplete="false" id="purchaseform">
				{/* Payment Provider Selection */}
				<AuxLayout>
					<h4 className="title">Purchase Summary</h4>
					<img className={"preview"} src={"https://ipfs.hivebp.io/ipfs/" + this.props.product.image} alt={""} />

					<div className={"summary"}>
						<b className={"summary-title"}>Product Information</b>
						<div className={"name"}>Pack: {this.props.product.name} - {this.props.product.edition} ({(this.props.product.price)} WAX)
						</div>
						<div className={"description"}>
							<ul className="description">
								{this.props.product.content.map((str, i) => {
									if (str.startsWith("Includes:")) return <u key={i}>{str}<br /></u>;
									return <li key={i}>{str}<br /></li>;
								})}
							</ul>
						</div>
					</div>
					<hr />
					<div>
						<label htmlFor={"quantity"}>How many packs do you want to buy? *</label> <small
						style={{ float: "right" }}>{this.fixSupplyCount(this.props.product.supply)} left</small><br />
						<div className={"quantity-changer"}>
							<button onClick={this.addQuantity.bind(this, -1)}>-</button>
							<input className="amount" id="quantity" cents="0" value={this.state.quantity} min={this.props.product.minPurchase}
								   max="200" type="number" name="amount" onChange={this.handleQuantity} placeholder="1-200" />

							<button onClick={this.addQuantity.bind(this, 1)}>+</button>
						</div>
					</div>
					<hr />
					<div className="" style={{ marginTop: "5px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<small style={{ fontSize: "18px" }}>Your Balance: <b>{this.state.balanceLoaded ? (this.state.balance.toFixed(2) + " WAX") : "loading"}</b></small>
					</div>
					<hr />
					<div className="" style={{ marginTop: "5px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<small style={{ fontSize: "18px" }}>Total: <b>{this.calculateTotal()} WAX</b></small>
						{
							this.state.balanceLoaded && this.state.balance < this.calculateTotal() ?
								<span className="buywax">You need to buy {(this.calculateTotal() - this.state.balance).toFixed(2)} WAX</span>
								: null
						}



						{


this.state.balance >= this.calculateTotal() ?
(
	this.state.timeRemaining > 0 && window.location.hash !== "#secretaccessplus" ?
		<div>BUY BUTTON WILL APPEAR IN <b>{this.secondsToString(this.state.timeRemaining / 1000)}</b></div>
	:
	 (
		<button className={"right " + (this.state.buyLoading ? 'grey' : '')} onClick={this.checkout}>
			{
				this.state.buyLoading ?
					<span className={"saving"}>Loading<span>.</span><span>.</span><span>.</span> </span>
					:
					<span>Checkout →</span>}
		</button>
	 )
)
:
(
	this.state.balanceLoaded && this.props.cfdata.loc !== "US" ?
		<button className={"right"}
				onClick={this.buyWAX.bind(this, (this.calculateTotal() - this.state.balance).toFixed(8))}>Buy {(this.calculateTotal() - this.state.balance).toFixed(2)} WAX
			→</button>
		: null
)

						}
						{
							this.state.buywax.enabled ?
								<MoonPayIntegration amount={parseFloat(this.state.buywax.amount)}
													onClose={() => this.setState({ buywax: { enabled: false, amount: 0 } })} />
								: null
						}
					</div>
				</AuxLayout>
			</form>
		)
	}
}


const InjectedCheckoutForm = (prop) => (
	<ElementsConsumer>
		{({ stripe, elements }) => (
			<CheckoutForm stripe={stripe} elements={elements}  {...prop} />
		)}
	</ElementsConsumer>
);

const stripePromise = StripeHandler.getNewStripeInstance();
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const PurchaseForm = (prop) => {
	return (
		<Elements stripe={stripePromise}>
			<InjectedCheckoutForm {...prop} />
		</Elements>
	);
};

export default PurchaseForm;
