import React, {Component} from 'react';
import Swal from 'sweetalert2'

import "./CreateOffer.css";

import * as API from "../../helpers/API";

import plus from "../../assets/icons/plus.svg";
import InventorySelector from "../../components/Inventory/InventorySelector";
import {withRouter, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import Button from '../../components/Button/Button'

import ItemView from '../ItemView';
import AtomicAssetsAPI from '../../helpers/AtomicAssetsAPI';
import config from '../../config/config';
import replaceIpfsHash from '../../helpers/ipfsDebugger';

class CreateOffer extends Component {

    _mounted = false;

    state = { 
        memo: "", 
        selectingItems: false, 
        selectingSide: 0, 
        own: {
            uid: this.props.userAccount, 
            items: [], 
            selected: []
        }, 
        partner: {
            uid: this.props.match.params.uid, 
            items: [], 
            selected: []
        }, 
        sent: false,

        loadingSendOffer: false
    };

    componentDidMount() {
        this._mounted = true;
        this.loadOwnInventory();
        this.loadPartnerinventory();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    loadOwnInventory = () => {
        AtomicAssetsAPI.getAssets({
            owner: this.props.userAccount,
            collection_name: config.collection_name
        }).then(inv => {
            let own = this.state.own;
            own.items = [...own.items, ...inv];
            this.setState({ own })
        })
    }

    loadPartnerinventory = () => {
        let partnerName = this.props.match.params.uid;
        AtomicAssetsAPI.getAssets({
            owner: partnerName,
            collection_name: config.collection_name
        }).then(inv => {
            let partner = this.state.partner;
            partner.items = [...partner.items, ...inv];
            this.setState({ partner })
        })
    }

    updateSelected = selected => {
        if (this.state.selectingSide === 0) {
            let newOwn = this.state.own;
            newOwn.selected = selected;
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (this.state.selectingSide === 1) {
            let newPartner = this.state.partner;
            newPartner.selected = selected;
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }

    removeItem = (side, item) => {
        if (side === 0) {
            let newOwn = this.state.own;
            newOwn.selected = newOwn.selected.filter(itemid => itemid !== item);
            if (this._mounted) {
                this.setState({ own: newOwn })
            }
        }
        if (side === 1) {
            let newPartner = this.state.partner;
            newPartner.selected = newPartner.selected.filter(itemid => itemid !== item);
            if (this._mounted) {
                this.setState({ partner: newPartner })
            }
        }
    }

    sendOffer = async () => {
        if (this._mounted) {
            this.setState({ loadingSendOffer: true });
        }
        try {
            let receipt = await API.createOffer(
                this.state.partner.uid, 
                this.state.own.selected.map(asset => asset.asset_id), 
                this.state.partner.selected.map(asset => asset.asset_id), 
                this.state.memo
            );
            if (this._mounted) {
                this.setState({sent: true})
            }
            await Swal.fire({
                title: 'Offer Sent!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        } catch(err) {
            console.error(err)
            await Swal.fire({
                title: 'Sending Offer failed!',
                text: err.toString(),
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        if (this._mounted) {
            this.setState({ loadingSendOffer: false });
        }
    }

    render() {
        if (this.state.sent) return <Redirect to={"/trades"} />
        let trader = {};
        if (this.state.selectingSide === 0) trader = this.state.own;
        if (this.state.selectingSide === 1) trader = this.state.partner;


        let items_to_send = [];

        let lastKey = 0;

        let ownCards = this.state.own.items.filter(item => this.state.own.selected.includes(item));
        for (let i = 0; i<ownCards.length; i++) {
            let item = ownCards[i];
            let name = item.name;
            let image = item.data.img;
            if (image && !image.includes("http")) image = "https://ipfs.hivebp.io/ipfs/" + image;
            image = replaceIpfsHash(image);

            items_to_send.push(<ItemView key={lastKey++} name={name} image={image} removalAction={this.removeItem.bind(this, 0, item)} />)
        }
        
        let items_to_receive = [];

        let partnerCards = this.state.partner.items.filter(item => this.state.partner.selected.includes(item));
        for (let i = 0; i<partnerCards.length; i++) {
            let item = partnerCards[i];
            let name = item.name, image = item.data.img;
            if (image && !image.includes("http")) image = "https://ipfs.hivebp.io/ipfs/" + image;
            image = replaceIpfsHash(image);

            items_to_receive.push(<ItemView key={lastKey++} name={name} image={image} removalAction={this.removeItem.bind(this, 0, item)} />)
        }
        return (
            <div className="" id="createoffer">
                <div className="box">
                    <div className="trader">
                            <div className="info">
                                <b className="name"><small>Items I want to send</small></b><br/>
                                <small className="action">{ this.state.own.selected.length } items selected</small>
                            </div>
                            <button onClick={() => this.setState({ selectingItems: true, selectingSide: 0 })} className="secondary">Select</button>
                    </div>
                    <div className="items">
                        <div className="addcard" onClick={() => this.setState({ selectingItems: true, selectingSide: 0 })}><img src={plus} alt="" /></div>
                        { items_to_send }
                    </div>
                </div>
                <div className="box">
                    <div className="trader">
                            <div className="info">
                                <b className="name"><small>Items I want from</small> { this.state.partner.uid }</b><br/>
                                <small className="action">{ this.state.partner.selected.length } items selected</small>
                            </div>
                            <button onClick={() => this.setState({ selectingItems: true, selectingSide: 1 })} className="secondary">Select</button>
                    </div>
                    <div className="items">
                        <div className="addcard" onClick={() => this.setState({ selectingItems: true, selectingSide: 1 })}><img src={plus} alt="" /></div>
                        { items_to_receive }
                    </div>
                </div>
                <div className="trade-actions">
                    <small>Message to {this.state.partner.uid}: </small>
                    <input type={"text"} className={"memo-input"} name={"memo"} placeholder={"Write a memo"} onInput={e => this.setState({memo: e.target.value})} />
                    <Button loading={this.state.loadingSendOffer ? 1 : 0} onClick={this.sendOffer}>Send Offer</Button>
                </div>

                <InventorySelector
                    uid={trader.uid}
                    items={trader.items}
                    selected={trader.selected}
                    updateSelection={this.updateSelected}
                    active={this.state.selectingItems}
                    close={() => this.setState({selectingItems: false})}/>

            </div>
    );
    }
}

const mapStateToProps  = state => {
    return {
        userAccount: state.userAccount
    }
}

export default connect(mapStateToProps)(withRouter(CreateOffer));
