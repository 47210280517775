import React, { Component } from 'react'
import Card from '../components/Card/Card'
import replaceIpfsHash from "../helpers/ipfsDebugger";

export default class ardView extends Component {

	render() {
		let img, backimg;
		img = this.props.item.data.img;
		backimg = this.props.item.data.backimg;
		if (img) img = img.includes("http") ? img : ("https://ipfs.hivebp.io/ipfs/" + img);
		if (backimg) backimg = backimg.includes("http") ? backimg : ("https://ipfs.hivebp.io/ipfs/" + backimg)
		img = replaceIpfsHash(img);
		backimg = replaceIpfsHash(backimg);

		return (
			<div className={"CardView " + (true ? ' portrait' : '')}
				 onClick={this.props.onClick}>
				<Card
					noFlip={true}
					src={img}
					id={this.props.item.id}
					cardid={this.props.item.data.cardid}
					back={backimg}
					className={"preview"}
					hideCombine={true}
				/>
			</div>
		)
	}
}
