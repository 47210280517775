import React, {Component} from 'react';

import Pagination from '../Pagination';
import ItemView from '../ItemView';

class PackSelector extends Component {
    render() {

        let items = [];
        let packs = JSON.parse(JSON.stringify(this.props.items));
        if (this.props.extra) items.push(this.props.extra)
        for (let i = 0; i<packs.length; i++) {
            let pack = packs[i];
            for (let j = 0; j<pack.amount; j++) {
                let name = `${pack.series} ${pack.edition}`, image = pack.img;
                if (image && !image.includes("http")) image = "https://ipfs.hivebp.io/ipfs/" + image;
                let isInPack = pack.selected && pack.selected.includes(j);
                items.push(<ItemView 
                    key={j} 
                    className={isInPack ? "selected " : ""} 
                    name={name} 
                    image={image} 
                    onClick={!isInPack ? () => this.props.addPack(pack, j) : () => this.props.removePack(pack, j)} 
                    removalAction={isInPack ? () => this.props.removePack(pack, j) : null} 
                />);
            }
        }
        return this.props.noPagination ? items : <Pagination wrapper={items => <div className="items">{items}</div>} items={items} />;
    }
}

export default PackSelector;
